import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import { initialAbility } from '@/plugins/acl/config'
import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import firebaseApp from './plugins/firebase'
import router from './router'
import store from './store'
import ability from './plugins/acl/ability'

Vue.use(abilitiesPlugin, ability)

const auth = getAuth()

onAuthStateChanged(auth, user => {
  if (user) {
    user.getIdToken(true)
      .then(idToken => {
        // console.log(idToken)
        // console.log(user.uid)
        const userAbility = [
          {
            action: 'manage',
            subject: 'all',
          },
        ]
        const userData = {
          uid: user.uid,
        }

        localStorage.setItem('accessToken', idToken)
        localStorage.setItem('userData', JSON.stringify(userData))
        localStorage.setItem('userAbility', JSON.stringify(userAbility))
        ability.update(userAbility)
      })

    // router.push('/home')
  } else {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userData')
    localStorage.removeItem('userAbility')

    // Reset ability
    ability.update(initialAbility)
    router.push({ name: 'auth-login' }).catch(() => {})
  }
})

new Vue({
  router,
  store,
  vuetify,
  firebaseApp,
  render: h => h(App),
}).$mount('#app')
