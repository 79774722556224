<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        :disabled="loading"
        color="primary">
        {{ getBalance }}
      </v-chip>


    </template>
  </v-menu>
</template>

<script>

import axios from '@axios'
import { getDatabase, ref, onValue } from 'firebase/database'

export default {
  data: () => ({
    balance: 0,
    loading: true,
  }),
  computed: {
    getBalance() {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
      })

      return formatter.format(this.balance)
    },
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.updateBalance()
    const db = getDatabase()
    const cardsRef = ref(db, `cards/${userData.uid}/providers/1/balance`)
    onValue(cardsRef, snapshot => {
      const data = snapshot.val()
      this.balance = data.available
      this.loading = false
    })
  },
  methods: {
    updateBalance() {
      axios.get('https://app.blket.com/api/v1/cards/balance')
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
