import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBS3FdRcftaC-7XafnDLQ3BKs0jY7W_aS0',
  authDomain: 'blket-42081.firebaseapp.com',
  databaseURL: 'https://blket-42081.firebaseio.com',
  projectId: 'blket-42081',
  storageBucket: 'blket-42081.appspot.com',
  messagingSenderId: '697992921275',
  appId: '1:697992921275:web:b0ca93ca9c3e869bcfa99e',
}

export default initializeApp(firebaseConfig)
