import { mdiCreditCardMultipleOutline, mdiFormatListText, mdiLogoutVariant } from '@mdi/js'

export default [
  {
    subheader: 'DASHBOARD',
  },
  {
    title: 'Activity',
    icon: mdiFormatListText,
    to: 'home',
  },
  {
    title: 'Cards',
    icon: mdiCreditCardMultipleOutline,
    to: 'cards',

    // resource: 'User',
    // action: 'read',
  },
  {
    subheader: 'ACCOUNT',
  },
  {
    title: 'Logout',
    to: 'logout',
    icon: mdiLogoutVariant,
  },
]
